import { css } from 'astroturf';
import React from 'react';

import About from '../About';
import CTA from '../CTA';
import SmallCTA from '../CTA-small-platforms';
import FeatureCloud from '../FeatureCloud';
import Kilian from '../Kilian2';
import Technologies from '../Technologies';
import Testimonials from '../Testimonials';
import Hero from './Hero';
import SyntaxTestimonials from './Testimonials';

const styles = css`
  .punchout {
    background: linear-gradient(135deg, #4ca2cd, ease-in-out, #67b26f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
  .punchout2 {
    background: linear-gradient(135deg, #e7b958, ease-in-out, #b89348);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .head {
    padding: 0rem 0 5rem;
    text-align: center;

    & > h2 {
      padding: 5rem 2rem 0rem;
      font-weight: 900;
      font-size: clamp(18px, 16px + 3vw, 40px);
      letter-spacing: -0.015em;
      color: #0a1530;
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

function Syntax() {
  return (
    <>
      <Hero />
      <SyntaxTestimonials />
      <About />
      <CTA />
      <Testimonials hideCTA />
      <Technologies />
      <section className={styles.head}>
        <h2>Polypane has hundreds of tools:</h2>
        <FeatureCloud type="light" />
        <SmallCTA />
      </section>
      <Kilian />
    </>
  );
}

export default Syntax;
