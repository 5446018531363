import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const styles = css`
  .wrap {
    position: relative;
    padding: 1rem;
    max-width: 600px;
    color: rgb(10, 21, 48);
    margin: 2rem 1rem;
    z-index: 1;

    @media (min-width: 40rem) {
      margin: 5rem auto -4rem;

      &::before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 99%;
        height: 99%;
        border-radius: 1rem;
        background: linear-gradient(to right bottom, rgb(114, 198, 239), rgb(0, 78, 143));
        transform: rotate(4deg);
        transform-origin: 40% 70%;
        box-shadow:
          rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset,
          rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
          rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
      }
    }
  }
  .bg {
    position: relative;
    z-index: 1;

    @media (min-width: 40rem) {
      box-shadow:
        rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
        rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
      background: #fff;
      border-radius: 1rem;
      padding: 3rem 3rem 2rem;
      @media (forced-colors: active) {
        border: 1px solid;
      }
    }
  }

  .image {
    margin: 0 auto 1rem;
    width: 110px;
    border-radius: 100px;
  }

  .header {
    text-align: center;
    margin-bottom: 1.5rem;

    @media (min-width: 40rem) {
      text-align: left;
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;

      & *:first-child {
        flex: 0 0 auto;
      }
      & *:last-child {
        margin-right: 2rem;
      }
    }
  }

  .cta {
    & * + * {
      margin-top: 1.5rem;
    }
  }
`;

export default () => (
  <div className={styles.wrap}>
    <div className={styles.bg}>
      <div className={styles.header}>
        <StaticImage
          src="../../assets/images/kilian.jpg"
          className={styles.image}
          alt="Kilian"
          title="Kilian"
          width={110}
        />
        <div>
          <h2 className="h1" style={{ fontSize: 'clamp(18px, 16px + 3vw, 40px)', fontWeight: 900 }}>
            Hi! 👋 I'm Kilian.
          </h2>
          <h3 className="h2">I'm the founder, developer, designer and support team at Polypane. Nice to meet you!</h3>
        </div>
      </div>
      <div className={styles.cta}>
        <div>
          <p>
            I've built close to 200 websites over the past decade, while continuously creating tools and libraries to
            improve my workflow. Polypane started as a prototype after getting frustrated that I always had to resize my
            browser. When I started using the prototype, I was shocked. I was instantly 60% faster compared to just
            using Chrome.
          </p>
          <p>
            And it's not just me. People keep telling me how much more efficient they are with Polypane. 2 times, 3
            times, some even 5 times as efficient!
          </p>

          <p>
            That's why I develop Polypane.{' '}
            <strong>
              To help devs and designers create the best web experiences they can, through a browser that puts them
              first.
            </strong>{' '}
            <a href="https://dashboard.polypane.app/register/">Give it a try!</a>
          </p>

          <p>
            By the way, <strong>Polypane is 100% bootstrapped</strong>. It's funded by users, so the only people I have
            to answer to are you.
          </p>

          <p>&mdash; Kilian Valkhof</p>
        </div>
      </div>
    </div>
  </div>
);
