import React, { Component } from 'react';
import SEO from '../components/SEO';
import Syntax from '../components/Syntax';
import Layout from '../layout';

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Hey Syntax.fm fans, thanks for checking out Polypane!"
          keywords={['']}
          pathname={this.props.location.pathname}
        />
        <Syntax />
      </Layout>
    );
  }
}

export default Page;
