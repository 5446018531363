import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const styles = css`
  .testimonials {
    border: 1px solid #abfb;
    background: linear-gradient(145deg, #012b, #0126, #012b);
    max-width: min(920px, 100vw - 2rem);
    margin: 4rem auto 0;
    padding: 0.6rem;
    border-radius: 1rem;
    box-shadow: rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.5) 0px 30px 60px -30px, rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
  }
  .innertestimonials {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
    position: relative;
    padding: 1.5rem 2rem;
    border-radius: 0.6rem;
    background: #0128;
    box-shadow: rgba(255, 255, 255, 0.6) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.7) 0px 5px 10px;
    color: #fff;
    @media (min-width: 700px) {
      flex-direction: row;
    }

    & .divider {
      display: block;
      width: 100px;
      height: 1px;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: rgba(0, 0, 0, 0.75) 0px 0px 70px 15px;

      @media (min-width: 700px) {
        margin-left: -1rem;
        height: 100px;
        width: 1px;
      }
    }
  }

  .smalltestimonial {
    flex: 1 1 33%;
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 700px) {
      align-items: flex-start;
      flex-direction: row;
    }

    & div.image {
      flex: 0 0 60px;
      aspect-ratio: 1;
      height: 60px;
      border-radius: 100%;
      box-shadow: rgba(255, 255, 255, 0.8) 0px -1px 0px 0px, rgba(50, 50, 93, 0.7) 0px 15px 30px;

      & img {
        border-radius: 100%;
      }
    }

    & div.quote {
      font-size: 1.1rem;
      text-align: left;

      & q,
      & span {
        display: block;
      }

      & span {
        font-size: 0.875rem;
        margin-top: 0.25rem;
        opacity: 0.75;
      }
    }
  }
`;

function Testimonals(props) {
  return (
    <div className={styles.testimonials}>
      <div className={styles.innertestimonials}>
        <div className={styles.smalltestimonial}>
          <div className={styles.image}>
            <StaticImage src="../../../assets/images/testimonials/stolinski.jpg" width={60} height={60} alt="" />
          </div>
          <div className={styles.quote}>
            <q>
              Polypane is really saving my bacon while building the new Level Up Tutorials website.{' '}
              <b>Kilian knocked it out of the park</b>, this is my new favorite development tool.
            </q>
            <span>
              <b>Scott Tolinski</b> - <small>Syntax.fm host, Level Up Tutorials</small>
            </span>
          </div>
        </div>

        {/* <div className={styles.divider} />

        <div className={styles.smalltestimonial}>
          <div className={styles.image}>
            <StaticImage src="../../../assets/images/testimonials/wesbos.jpg" width={60} height={60} alt="" />
          </div>
          <div className={styles.quote}>
            <q>
              Polypane helped me see a spacing issue between two breakpoints that I wasn’t aware of. <b>Love it 💯</b>
            </q>
            <span>
              <b>Wes Bos</b> - <small>Syntax.fm host, course instructor</small>
            </span>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Testimonals;
