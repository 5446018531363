import { css } from 'astroturf';
import React from 'react';
import { ArrowRight } from 'react-bytesize-icons';
import Platforms from '../Platforms';
import Punchout from '../Punchout';
import Link from '../link';
import BeatingHeart from './BeatingHeart';
import Hero3D from './Hero-3D';

const styles = css`
  @keyframes fadein {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .hero {
    z-index: 1;
    position: relative;
    padding: 70px 3vw 10rem;
    padding-left: calc(3vw + env(safe-area-inset-left, 0));
    padding-right: calc(3vw + env(safe-area-inset-right, 0));
    text-align: center;
    overflow: hidden;
    margin-bottom: -9rem;

    @supports (overflow: clip) {
      overflow: clip visible;

      margin-bottom: 3rem;
      padding: 70px 3vw 1rem;
    }

    & header {
      position: relative;
      padding-bottom: 0rem;
      max-width: 840px;
      margin: 0 auto 4rem;

      @media (min-width: 1024px) {
        padding-bottom: 4rem;
      }

      & > div > svg {
        max-width: 180px;
        width: 100%;
      }
      & h1 {
        font-weight: 900;
        font-size: clamp(25px, 6vw, 50px);
        line-height: 1;
        margin: 0.5rem auto 1.25rem;
        padding-bottom: 0.22ch;
        padding-top: 0.25em;
        color: #0a1530;
        width: 100%;
        max-width: 18ch;
        max-height: calc(1.3 * 4 * clamp(25px, 3.7vw, 42px));
        overflow: hidden;
      }

      & h2 {
        font-size: 1.1em;
        line-height: 1.5;
        font-weight: 400;
        max-width: 40rem;
        margin: 0 auto 1rem;
        color: #0a1530;

        @media (min-width: 1024px) {
          padding-left: 0.125rem;
        }
      }
    }

    @media (min-width: 1024px) {
      padding-top: 100px;

      & .split {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 auto;
        max-width: 1200px;

        & > * {
          flex: 1 0 48%;

          &.imageWrapper {
            flex: 1 1 48%;
            padding: 0;
          }
        }

        @media (min-width: 1920px) {
          max-width: 1440px;
          justify-content: center;

          & > * {
            flex: 0 1 720px;
          }
        }

        & header {
          padding-bottom: 0;
          margin-bottom: 0;
          text-align: left;
          margin-right: 2rem;

          & h1 {
            margin-left: 0;
          }

          & h2 {
            margin-left: 0;
          }
        }
      }
    }
  }

  .primaryButton {
    position: relative;
    pointer-events: all;
    margin: 0;
    font-size: calc(14px + 0.5vw);
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    background: linear-gradient(135deg, #2c82ad, #67b26f);
    border-radius: 40px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    padding: 0;
    margin-bottom: 1.5rem;
    @media (forced-colors: active) {
      border: 1px solid;
    }

    & i {
      position: absolute;
      top: calc(100% + 10px);
      line-height: 1;
      left: 1rem;
      right: 0;
      width: 120%;
      text-align: left;
      font-size: 12px;
      font-weight: normal;
      color: #506478;
      background: #f7faff;
      display: block;
      font-style: normal;
    }

    & span {
      position: relative;
      z-index: 1;
      padding: 0.33rem 1rem;
      content: ' ';
      display: block;
      background: transparent;
      width: calc(100%);
      height: calc(100%);
      z-index: 0;
      opacity: 1;
      transition: background 0.25s ease-in-out;
      border-radius: 40px;
    }

    & svg {
      vertical-align: middle;
      margin-left: 10px;
    }

    @media (min-width: 600px) {
      & span {
        font-size: 1.125rem;
      }
    }

    &:hocus {
      text-decoration: none;
      & span {
        background: #0a1530;
      }
    }
  }
  .punchout2 {
    background: linear-gradient(135deg, #e7b958, ease-in-out, #b89348);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
`;

export default () => (
  <div className={styles.hero}>
    <div className={styles.split}>
      <header>
        <div className={styles.textwrap}>
          <BeatingHeart />
          <h1 aria-label="Hey Syntax.fm fans, Polypane is the best way to develop amazing sites.">
          Hey <span className={styles.punchout2}>Syntax.fm</span> fans,{' '}
          <Punchout>Polypane</Punchout> is the best way to develop amazing sites.
          </h1>
          <h2>
          Polypane is the browser for developers with tools that help with literally every part of modern web
          development, helping you save hours (and frustration!) with every project.
          </h2>

          <div>
            <Link className={styles.primaryButton} to="https://dashboard.polypane.app/register/">
              <span>
                Try Polypane for free <ArrowRight width={16} height={16} strokeWidth="4px" />
              </span>
              <i>Free trial. No credit card needed. Cancel anytime.</i>
            </Link>
            <span>
              <Platforms />
            </span>
          </div>
        </div>
      </header>

      <div className={styles.imageWrapper}>
        <Hero3D />
      </div>
    </div>
  </div>
);
