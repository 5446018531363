import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import BeatingHeart from '../BeatingHeart';

function SyntaxHeart() {
  return (
    <BeatingHeart inHero color="#e7b958">
      <StaticImage src="../../../assets/images/syntax.png" alt="" objectFit="contain" placeholder="none" width={150} />
    </BeatingHeart>
  );
}

export default SyntaxHeart;
